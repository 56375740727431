const theme = {
  colors: {
    base: '#222', // Dark Gray
    secondary: '#e9e9e9', // Medium Gray
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#2979ff', // Light Blue
  },
  sizes: {
    maxWidth: '1280px',
    maxWidthCentered: '1280px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
