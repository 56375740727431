import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FaInstagram } from 'react-icons/fa'

const Links = styled.ul`
  grid-area: sidebar;
  padding: 3em 1.5em 2em;
  font-weight: 600;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.base};
  }

  a:hover {
    border-bottom: 3px ${props => props.theme.colors.highlight} solid;
  }

  @media all and (min-width: 801px) {
    padding: 3em 1.5em 2em;
  }
  @media all and (max-width: 800px) {
    padding: 1em 1.5em 1em 1.5em;
    grid-area: sidebar;
  }
`

const Item = styled.li`
  @media all and (min-width: 801px) {
    display: inline-block;
    margin-bottom: 1.5em;
    width: 100%;
  }
  @media all and (max-width: 800px) {
    padding: 0em 1.5em 0.5em 0em;
  }
`
const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: start;
`

const SocialIcon = styled.li`
  padding-right: 0.5em;

  a:hover {
    border-bottom: none;
    color: ${props => props.theme.colors.highlight} !important;
  }
`

const Sidebar = props => {
  return (
    <Links>
      <Item>
        <Link to="/">Home</Link>
      </Item>
      <Item>
        <Link to="/the-story">The Story</Link>
      </Item>
      <Item>
        <Link to="/plankton-types">Plankton Types</Link>
      </Item>
      <Item>
        <Link to="/contact">Contact</Link>
      </Item>
      <Item>
        <SocialList>
          <SocialIcon>
            <a href="https://www.instagram.com/planktonpatrol/" alt="Instagram">
              <FaInstagram />
            </a>
          </SocialIcon>
        </SocialList>
      </Item>
    </Links>
  )
}

export default Sidebar
