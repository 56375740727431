import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'

const Text = styled.p`
  line-height: 1.6;
  a {
    color: ${props => props.theme.colors.base};
  }
`

const Subtitle = styled.h2`
  font-weight: 600;
  font-size: 1.35rem;
  margin: 1rem 0;
`

const List = styled.ul`
  width: 100%;
  margin: 0 auto 1em auto;
`

const Tag = styled.li`
  display: inline-block;
  margin: 0 0.25em 0.25em 0;
  a {
    float: left;
    transition: 0.2s;
    background: ${props => props.theme.colors.tertiary};
    padding: 0.5em;
    border-radius: 2px;
    text-transform: capitalize;
    text-decoration: none;
    color: ${props => props.theme.colors.base};
    border: 1px solid ${props => props.theme.colors.secondary};
    &:hover {
      background: ${props => props.theme.colors.highlight};
      color: white;
    }
  }
`

const TagsPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Plankton Types</title>
        <meta name="description" content="Plankton Types" />
      </Helmet>

      <Container>
        <PageTitle>Plankton Types</PageTitle>
        <Text>
          Below are all types of plankton tagged to date. Click to explore.
        </Text>
        <Subtitle>Tags:</Subtitle>
        <List>
          {data.allContentfulTag.edges.map(tag => (
            <Tag key={tag.node.id}>
              <Link to={`/tag/${tag.node.slug}/`}>{tag.node.title}</Link>
            </Tag>
          ))}
        </List>
      </Container>
    </Layout>
  )
}
export default TagsPage

export const query = graphql`
  query {
    allContentfulTag {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
