import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import styled from 'styled-components'

const GridWrapper = styled.div`
  display: grid;
  max-width: 1280px;
  margin: 0 auto;
  grid-template-columns: 200px auto;
  grid-template-rows: 120px auto 150px;
  grid-template-areas:
    'blank header'
    'sidebar main'
    'footer footer';

  @media all and (max-width: 800px) {
    grid-template-columns: auto;
    grid-template-rows: 150px auto auto 150px;
    grid-template-areas:
      'header'
      'main'
      'sidebar'
      'footer';
  }
`

const Template = ({ children }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <GridWrapper>
          <Header />
          <Sidebar />
          {children}
          <Footer />
        </GridWrapper>
      </ThemeProvider>

      <GlobalStyle />
    </div>
  )
}

export default Template
