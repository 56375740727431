import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Title = styled.header`
  grid-area: header;
  background: none;
  margin: 0 auto auto;
  width: 100%;
  padding: 2em 1.5em 1.5em;

  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  a {
    color: ${props => props.theme.colors.base};
    text-decoration: none;
  }
`

const Subtitle = styled.p`
  padding: 0 0 0 0;
  color: ${props => props.theme.colors.base};
`

const Menu = () => {
  return (
    <Title>
      <h1>
        <Link to="/">Plankton Patrol</Link>
      </h1>
      <Subtitle>A completely unscientific survey of plankton</Subtitle>
    </Title>
  )
}

export default Menu
