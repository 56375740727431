import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 2em 1.5em 2em;
  grid-area: main;

  @media all and (max-width: 800px) {
    padding: 1.5em 1.5em 2em;
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
